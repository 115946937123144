import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['cartItemIcon', 'cartItemCount']

  setCartItemCount(count){
    this.cartItemCountTarget.innerText = count

    if (count > 0) {
      this.cartItemIconTarget.classList.remove("hidden")
      this.cartItemCountTarget.classList.remove("hidden")
    } else {
      this.cartItemIconTarget.classList.add("hidden")
      this.cartItemCountTarget.classList.add("hidden")
    }
  }

  updateCart(e) {
    const cartItemCount = e.detail.cart_item_count
    this.setCartItemCount(cartItemCount)
  }
}
