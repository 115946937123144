import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { getSiblings } from 'components/tools'
import * as Sentry from "@sentry/browser";
import { trackingEvent } from "../components/tracking_event";
import { formatCurrency } from "../components/exchange_rates";
export default class extends Controller {
  static targets = [
    'loginBtn',
    'addToCartBtn',
    'inCartBtn',
    'purchasedBtn',
    'discountedPrice',
    'price',
    'content',
    'variantTypePresupported',
    'variantTypeNonsupported',
    'variantTypeSliced',
    'variantPrinterMighty12k',
    'variantPrinterMega8ks',
    'variantPrinterMega8k',
    'variantPrinterMega8kv2',
    'variantPrinterMighty8k',
    'variantPrinterMighty4k',
    'variantPrinterMini8k',
    'variantPrinterMini4k',
    'includeOriginalFile',
    'excludeOriginalFile',
    'variantPhrozenDisclaimer',
    'variantCtbDisclaimer',
  ]
  static classes = ['hidden']
  static values = {
    itemId: String // post id
  }
  postInfo = null
  selectedVariantId = null
  variantInfoData = {}

  // 這邊會跟著 models/post.rb PRINTER_TYPES
  printerTypes = ['Mega8ks','Mighty8k','Mighty4k','Mini8k','Mini4k']

  connect() {
    // init selected variant id
    const selectVariantEl = document.querySelector('.variant-option.selected')
    if (selectVariantEl) {
      this.selectedVariantId = selectVariantEl.dataset.id
      this.getVariantInfo()
    }

    // get post info for ga
    Rails.ajax({
      url: `/api/v1/posts/${this.itemIdValue}`,
      type: "GET",
      success: resp => {
        this.postInfo = resp.data
        trackingEvent.view_item(this.postInfo)
      },
      error: resp => {
        Sentry.captureException(resp);
      }
    });
  }

  addItem(e) {
    e.preventDefault()

    if(!this.selectedVariantId) {
      alert('Please choose file format')
      return
    }

    const formData = new FormData()
    formData.append('id', this.selectedVariantId)

    Rails.ajax({
      url: "/cart_items",
      data: formData,
      type: "POST",
      success: resp => {
        if (resp.status === 'ok') {
          // update cart icon number
          let evt = new CustomEvent('addToCart', { 'detail': { 'cart_item_count':  resp.cart_item_count} });
          document.dispatchEvent(evt);

          // 發送 add to cart ga event
          let variantInfo = this.variantInfoData[this.selectedVariantId]
          trackingEvent.add_to_cart(variantInfo)

          // change variant incart
          this.setVariantInCart()

          this.enableInCartBtn()
        }
      },
    });
  }

  setVariantInCart(){
    this.variantInfoData[this.selectedVariantId].in_cart = true
  }

  enableAddToCartBtn() {
    if(this.hasAddToCartBtnTarget) {
      this.addToCartBtnTarget.classList.remove('hidden')
    }
    this.inCartBtnTarget.classList.add('hidden')
    this.purchasedBtnTarget.classList.add('hidden')
  }

  enableInCartBtn() {
    if(this.hasAddToCartBtnTarget) {
      this.addToCartBtnTarget.classList.add('hidden')
    }
    this.inCartBtnTarget.classList.remove('hidden')
    this.purchasedBtnTarget.classList.add('hidden')
  }

  enablePurchasedBtn() {
    if(this.hasAddToCartBtnTarget) {
      this.addToCartBtnTarget.classList.add('hidden')
    }
    this.inCartBtnTarget.classList.add('hidden')
    this.purchasedBtnTarget.classList.remove('hidden')
  }

  selectVariant(e) {
    const variantEl = e.currentTarget
    this.selectedVariantId = variantEl.dataset.id

    const variantInfo = this.variantInfoData[this.selectedVariantId]

    if (variantInfo) {
      this.enableVariantInfo(variantInfo)
    } else {
      this.getVariantInfo()
    }

    // change variant statut
    variantEl.classList.add('selected')

    const siblings = getSiblings(variantEl)
    for( const sibling of siblings ) {
      sibling.classList.remove('selected')
    }
  }

  getVariantInfo() {
    Rails.ajax({
      url: `/api/v1/post_variants/${this.selectedVariantId}`,
      type: "GET",
      success: resp => {
        const variantInfo = resp['data']

        this.variantInfoData[this.selectedVariantId] = variantInfo

        this.enableVariantInfo(variantInfo)
      },
      error: resp => {
        Sentry.captureException(resp);
      }
    })
  }

  enableVariantInfo(variantInfo) {
    const variantDiscountedPrice = parseFloat(variantInfo.price)
    const variantPrice           = parseFloat(variantInfo.original_price)
    // change add to cart status
    if(!isNaN(variantPrice)) {
      this.discountedPriceTarget.innerText = formatCurrency(variantDiscountedPrice)

      // origin price
      if(this.hasPriceTarget) {
        if(variantPrice > variantDiscountedPrice) {
          this.priceTarget.innerText = formatCurrency(variantPrice)
        } else {
          this.priceTarget.innerText = ''
        }
      }

      if (!variantInfo.post.adv_post) {
        return
      }

      this.contentTarget.innerHTML = variantInfo.content

      // change variant file type
      if ( variantInfo.include_pre_supported ) {
        this.variantTypePresupportedTarget.style.opacity = 1
      } else {
        this.variantTypePresupportedTarget.style.opacity = 0.5
      }

      if ( variantInfo.include_non_supported ) {
        this.variantTypeNonsupportedTarget.style.opacity = 1
      } else {
        this.variantTypeNonsupportedTarget.style.opacity = 0.5
      }

      if ( variantInfo.include_sliced ) {
        this.variantTypeSlicedTarget.style.opacity = 1
      } else {
        this.variantTypeSlicedTarget.style.opacity = 0.5
      }

      // show printer type
      const event = this
      this.printerTypes.forEach(function(printer){
        const printer_target = 'variantPrinter' + printer + 'Target'
        event[printer_target].style.opacity = 0.5
      })
      if ( variantInfo.include_sliced ) {
        variantInfo.printer_type.forEach(function(printer){
          const printer_target = 'variantPrinter' + printer + 'Target'

          if (event[printer_target]) {
            event[printer_target].style.opacity = 1
          }
        })
      }

      // show original stl file
      if ( variantInfo.include_non_supported ) {
        this.includeOriginalFileTarget.classList.remove('hidden')
        this.excludeOriginalFileTarget.classList.add('hidden')
      } else {
        this.includeOriginalFileTarget.classList.add('hidden')
        this.excludeOriginalFileTarget.classList.remove('hidden')
      }

      // change variant file format
      const variantFileFormat = document.querySelector('[data-variant-file-format = "' + variantInfo.id + '"]')
      const fileFormatSiblings = getSiblings(variantFileFormat)
      for( const sibling of fileFormatSiblings ) {
        sibling.classList.add('hidden')
      }
      variantFileFormat.classList.remove('hidden')

      // show disclaimer
      if ( variantInfo.phrozen_verified && variantInfo.include_pre_supported ) {
        this.variantPhrozenDisclaimerTarget.classList.remove('hidden')
      } else {
        this.variantPhrozenDisclaimerTarget.classList.add('hidden')
      }

      if ( variantInfo.include_sliced ) {
        this.variantCtbDisclaimerTarget.classList.remove('hidden')
      } else {
        this.variantCtbDisclaimerTarget.classList.add('hidden')
      }

      window.contentEllipsis()
    }

    // 假如未登入會有 LoginBtnTarget
    if(!this.hasLoginBtnTarget) {
      if(variantInfo.purchased) {
        this.enablePurchasedBtn()
      } else if (variantInfo.in_cart) {
        this.enableInCartBtn()
      } else {
        this.enableAddToCartBtn()
      }
    }
  }
}
