import { Controller } from "stimulus"
import { format, differenceInDays } from 'date-fns'

// Storage Management Class
class AnnouncementStorage {
  static getItem() {
    try {
      return JSON.parse(localStorage.getItem('announce')) || null
    } catch (error) {
      console.error('Error reading from localStorage:', error)
      return null
    }
  }

  static setItem(data) {
    try {
      localStorage.setItem('announce', JSON.stringify(data))
    } catch (error) {
      console.error('Error writing to localStorage:', error)
    }
  }
}

// Announcement Controller
export default class extends Controller {
  static targets = ['closeBtn', 'days', 'hours', 'minutes', 'seconds']
  static values = {
    id: String,
    updatedAt: String,
    remainingTime: Number,
    showCountdown: Boolean
  }

  currentAnnounceInfo = null
  oldAnnounceInfo = null
  countdownInterval = null
  remainingSeconds = 0
  defaultShowDays = 7

  connect() {
    // localStorage format
    // {
    //   close: true,
    //   id: "19",
    //   timestamp: 1721113287123,
    //   updatedAt: "2024-07-16",
    //   closeTimes: 0
    // }

    this.initializeAnnouncement()

    // 公告有更新或不同公告會顯示
    if (this.shouldShowAnnouncement()) {
      this.show()
      this.initializeCountdown()
    }

    if (this.element.classList.contains('hidden') && this.countdownInterval) {
      this.stopCountdown()
    }
    this.adjustAnnouncementStyle()
  }

  disconnect() {
    this.stopCountdown()
  }

  initializeAnnouncement() {
    this.currentAnnounceInfo = {
      id: this.idValue,
      updatedAt: format(new Date(this.updatedAtValue), 'yyyy-MM-dd'),
      timestamp: Date.now(),
      remainingTime: this.remainingTimeValue,
      showCountdown: this.showCountdownValue
    }
    this.oldAnnounceInfo = AnnouncementStorage.getItem()
  }

  initializeCountdown() {
    if (this.currentAnnounceInfo.remainingTime && this.hasAllCountdownTargets()) {
      this.startCountdown()
    }
  }

  shouldShowAnnouncement() {
    // 如果没有舊公告，公告ID或更新時間變化，顯示新公告
    if (this.isNewOrUpdatedAnnouncement()) {
      this.updateAnnouncementInfo(true)
      return true
    }

    const daysSinceLastShown = this.getDaysSinceLastShown()

    // 啟動倒數計時，且距離上次顯示過去1天，顯示公告 or
    // 距離上次顯示過去defaultShowDays天，且關閉次數少於2次，顯示公告
    if (this.shouldShowCountdown(daysSinceLastShown) || this.shouldShowAfterDays(this.defaultShowDays, daysSinceLastShown)) {
      this.updateAnnouncementInfo(false)
      return true
    }

    // 如果公告未被關閉，顯示公告
    return this.oldAnnounceInfo && !this.oldAnnounceInfo.closed
  }

  isNewOrUpdatedAnnouncement() {
    return !this.oldAnnounceInfo || 
            this.oldAnnounceInfo.id !== this.currentAnnounceInfo.id || 
            this.oldAnnounceInfo.updatedAt !== this.currentAnnounceInfo.updatedAt
  }

  shouldShowCountdown(daysSinceLastShown) {
    return this.currentAnnounceInfo.showCountdown && daysSinceLastShown >= 1
  }

  shouldShowAfterDays(showDays, daysSinceLastShown) {
    return daysSinceLastShown >= showDays && (this.oldAnnounceInfo?.closeTimes || 0) < 2
  }

  getDaysSinceLastShown() {
    const lastShownTime = this.oldAnnounceInfo?.timestamp ? parseInt(this.oldAnnounceInfo.timestamp) : 0
    return differenceInDays(Date.now(), lastShownTime)
  }

  updateAnnouncementInfo(isReset) {
    this.oldAnnounceInfo = {
      ...this.currentAnnounceInfo,
      closed: false,
      closeTimes: isReset ? 0 : (this.oldAnnounceInfo?.closeTimes || 0)
    }
    AnnouncementStorage.setItem(this.oldAnnounceInfo)
  }

  show() {
    this.element.classList.remove('hidden')
    this.adjustAnnouncementStyle()
  }
  hide() {
    this.element.classList.add('hidden')
    this.adjustAnnouncementStyle()
    this.updateAnnouncementStorage()
    this.stopCountdown()
  }

  updateAnnouncementStorage() {
    if (this.oldAnnounceInfo) {
      AnnouncementStorage.setItem({
        ...this.oldAnnounceInfo,
        closed: true,
        closeTimes: (this.oldAnnounceInfo.closeTimes || 0) + 1
      })
    }
  }

  startCountdown() {
    this.remainingSeconds = parseInt(this.currentAnnounceInfo.remainingTime, 10)
    this.updateCountdown()

    this.countdownInterval = setInterval(() => {
      this.remainingSeconds -= 1
      if (this.remainingSeconds <= 0) {
        this.stopCountdown()
      } else {
        this.updateCountdown()
      }
    }, 1000)
  }

  updateCountdown() {
    const days = Math.floor(this.remainingSeconds / 86400)
    const hours = Math.floor((this.remainingSeconds % 86400) / 3600)
    const minutes = Math.floor((this.remainingSeconds % 3600) / 60)
    const seconds = this.remainingSeconds % 60

    if (this.hasDaysTarget) this.daysTarget.textContent = String(days).padStart(2, '0')
    if (this.hasHoursTarget) this.hoursTarget.textContent = String(hours).padStart(2, '0')
    if (this.hasMinutesTarget) this.minutesTarget.textContent = String(minutes).padStart(2, '0')
    if (this.hasSecondsTarget) this.secondsTarget.textContent = String(seconds).padStart(2, '0')
  }

  stopCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
      this.countdownInterval = null
    }
    if (this.remainingSeconds <= 0) {
      this.element.textContent = "Event has ended"
    }
  }

  hasAllCountdownTargets() {
    return this.hasHoursTarget && this.hasDaysTarget && this.hasMinutesTarget && this.hasSecondsTarget
  }

  // Update CSS variables
  adjustAnnouncementStyle() {
    requestAnimationFrame(() => {
      const isVisible = !this.element.classList.contains('hidden');
      const height = this.element.offsetHeight;

      document.documentElement.style.setProperty('--announcement-visible', isVisible ? '1' : '0');
      document.documentElement.style.setProperty('--announcement-height', height.toString());

    });
  }
}

