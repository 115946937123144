import { Controller } from "stimulus"

const SHARE_ON_FACEBOOK = "[data-share-facebook]"
const SHARE_ON_TWITTER = "[data-share-twitter]"
const SHARE_ON_WHATSAPP = "[data-share-whatsapp]"
const SHARE_ON_REDDIT = "[data-share-reddit]"
const SHARE_ON_PINTEREST = "[data-share-pinterest]"
const SHARE_ON_EMAIL = "[data-share-email]"
const SHARE_ON_COPY = "[data-share-copy]"

export default class extends Controller {
  static targets = ['panel']
  shouldAddGlobalClickListener = false;

  dropdownBtnClicked(e) {
    e.preventDefault()
    e.stopPropagation()
    this.toggleDisplay()
    
    if (!this.globalClickListenerAdded) {
      document.body.addEventListener('click', () => {
        this.hiddenDisplay()
      }); 
      this.globalClickListenerAdded = true;
    }
  }

  dropdownSharedBtnClicked(e) {
    e.preventDefault()
    e.stopPropagation()
    this.toggleDisplay()

    this.shareOnFacebook()
    this.shareOnTwitter()
    this.shareOnWhatsapp()
    this.shareOnReddit()  
    this.shareOnPinterest()  
    this.shareOnEmail()
    this.shareOnCopy()
  }

  hiddenDisplay() {
    this.element.classList.add('close')
    this.panelTarget.classList.add('hidden')
  }

  toggleDisplay() {
    this.element.classList.toggle('close')
    this.panelTarget.classList.toggle('hidden')
  }

  shareOnFacebook () {
    const $elements = document.querySelectorAll(SHARE_ON_FACEBOOK)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postLink = e.target.parentElement.parentElement.dataset.link

        window.trackingEvent.share({
          method: 'facebook',
          content_type: 'post',
          item_id: postLink,
        })

        FB.ui({
          method: "share",
          href: postLink,
        })
      })
    )
  }

  shareOnTwitter () {
    const $elements = document.querySelectorAll(SHARE_ON_TWITTER)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement
        
        window.trackingEvent.share({
          method: 'twitter',
          content_type: 'post',
          item_id: postEl.dataset.link,
        })

        window.open(
          `https://twitter.com/share?url=${postEl.dataset.link}&text=${postEl.dataset.title}`,
          "_blank"
        )
      })
    )
  }

  shareOnWhatsapp () {
    const $elements = document.querySelectorAll(SHARE_ON_WHATSAPP)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement
        
        window.trackingEvent.share({
          method: 'whatsapp',
          content_type: 'post',
          item_id: postEl.dataset.link,
        })

        window.location.href = "https://api.whatsapp.com/send?text=" + postEl.dataset.link
      })
    )
  }

  shareOnReddit () {
    const $elements = document.querySelectorAll(SHARE_ON_REDDIT)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement
        
        window.trackingEvent.share({
          method: 'reddit',
          content_type: 'post',
          item_id: postEl.dataset.link,
        })

        window.open(
          `https://reddit.com/submit?url=${encodeURIComponent(postEl.dataset.link)}&title=${postEl.dataset.title}`,
          "_blank"
        )
      })
    )
  }

  shareOnPinterest () {
    const $elements = document.querySelectorAll(SHARE_ON_PINTEREST)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement
        
        window.trackingEvent.share({
          method: 'pinterest',
          content_type: 'post',
          item_id: postEl.dataset.link,
        })

        window.open(
          `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(postEl.dataset.link)}&media=${encodeURIComponent(postEl.dataset.image)}&description=${encodeURIComponent(postEl.dataset.title)}`,
          "_blank"
        )
      })
    )
  }

  shareOnEmail () {
    const $elements = document.querySelectorAll(SHARE_ON_EMAIL)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        const postEl = e.target.parentElement.parentElement

        window.location.href = `mailto:?subject=${postEl.dataset.title}&body=${postEl.dataset.link}`
      })
    )
  }

  shareOnCopy () {
    const $elements = document.querySelectorAll(SHARE_ON_COPY)
    if (!$elements) {
      return
    }

    $elements.forEach((item) =>
      item.addEventListener("click", (e) => {
        e.preventDefault()

        e.target.firstElementChild.style.visibility = "visible"
        setTimeout(function () {
          e.target.firstElementChild.style.visibility = "hidden"
        }, 1000)

        const copyArea = e.target.parentElement.parentElement.dataset.link
        navigator.clipboard.writeText(copyArea)
      })
    )
  }
}
